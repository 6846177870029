.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns children to the left */
    width: 100%; /* Takes full width of its parent */
    justify-content: center;  /* Centers children horizontally in the container */
  align-items: center;      /* Centers children vertically in the container */
  }
  
  .dashboard-container > * {
    margin-left: 0; /* Ensures no additional margin is pushing the content away from the left edge */
  }
  
  .dashboard-container .listdescriptors {
    color: white; /* Sets the text color to white */
    display: inline-block; /* Aligns the heading and button inline */
    margin-right: 10px; /* Adds some space between the heading and the button */
}

.dashboard-container .listadder {
    background-color: white; /* Sets the button background to white */
    border: none; /* Removes the default border of the button */
    color: #333; /* Sets a text color for the button; adjust as needed */
    display: inline-block; /* Ensures the button is inline with the heading */
    vertical-align: middle; /* Aligns the button vertically to the middle */
    cursor: pointer; /* Changes the cursor to a pointer when hovering over the button */
}

.profile-row,
.badge-row,
.card-container-row {
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 5vh;
}

.list-descriptors {
  color: white;
  margin-right: 10px; /* Spacing between the text and the button */
  margin-left: 35px;
}

.list-adder {
  all: initial;               /* Reset all inherited properties */
  background-color: white;    /* Your specific styles start here */
  border: 2px solid gray;     /* Example border reset */
  color: #333;                /* Text color */
  cursor: pointer;            /* Cursor on hover */
  padding: 10px 20px;         /* Custom padding */
  font-size: 16px;            /* Reset font-size */
}


/* Ensuring the CardContainerLB takes its own row */
.card-container-row {
  width: 100%;
}

.badge-row {
  justify-content: flex-start;
}
