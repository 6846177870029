@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,700');
html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Saria", sans-serif;
    font-size: 62.5%;
    font-size: 10px;
}
/*-- Inspiration taken from abdo steif -->
/* --> https://codepen.io/abdosteif/pen/bRoyMb?editors=1100*/

/* Navbar section */

/* Overriding the button style to make it square, light blue with white text */
/* Overriding the button style for navy blue background and snugger text fit */
.login-button {
    background-color: #4245f5; /* Navy blue background */
    color: white;              /* White text */
    border: none;              /* No border for a cleaner look */
    cursor: pointer;           /* Pointer cursor on hover */
    font-size: 1.6rem;         /* Appropriate font size */
    font-weight: 700;          /* Bold font weight */
    padding : 0;                /* Minimal padding to reduce space between text and button border */
    width: 85px;               /* Width and height set to 50px to maintain square shape */
    height: 35px;
    display: flex;             /* Flex display to center content */
    justify-content: center;   /* Center content horizontally */
    align-items: center;       /* Center content vertically */
    text-align: center;        /* Ensure text is centered horizontally */
    border-radius: 5px;        /* Slightly rounded corners for aesthetics */
    transition: background-color 0.3s ease-in-out; /* Smooth background color transition on hover */
}

.login-button:hover {
    background-color: #1a1aff; /* Slightly lighter navy blue on hover for interactivity */
}

.whitepaper-button {
    background-color: whitesmoke; /* Navy blue background */
    color: black;              /* White text */
    border: none;              /* No border for a cleaner look */
    cursor: pointer;           /* Pointer cursor on hover */
    font-size: 1.6rem;         /* Appropriate font size */
    font-weight: 700;          /* Bold font weight */
    padding : 0 5rem 0 5rem;                /* Minimal padding to reduce space between text and button border */
    width: 85px;               /* Width and height set to 50px to maintain square shape */
    height: 35px;
    display: flex;             /* Flex display to center content */
    justify-content: center;   /* Center content horizontally */
    align-items: center;       /* Center content vertically */
    text-align: center;        /* Ensure text is centered horizontally */
    border-radius: 5px;        /* Slightly rounded corners for aesthetics */
    transition: background-color 0.3s ease-in-out; /* Smooth background color transition on hover */
}

.whitepaper-button:hover {
    background-color: #f8f8f8; /* Slightly lighter navy blue on hover for interactivity */
}

.nav {
    z-index: 100;
    width: 100%;
    height: 65px;
    position: relative; /* Change position to relative */
    line-height: 65px;
    text-align: center;
    padding-top: 65px; /* Add padding equal to the height of the navbar */
}


.nav div.logo {
    float: left;
    width: auto;
    height: auto;
    padding-top: 1rem;
    padding-left: 3rem;
}

.nav div.logo a {
    text-decoration: none;
    color: #fff;
    font-size: 2.5rem;
}

.nav div.logo a:hover {
    color: #37F0FF;
}

.nav div.main_list {
    height: 65px;
    float: right;
}

.nav div.main_list ul {
    width: 100%;
    height: 65px;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav div.main_list ul li {
    width: auto;         /* Allows the <li> to grow or shrink based on its content */
    height: 65px;        /* Fixed height for each <li> */
    padding: 0;          /* Removes all default padding */
    padding-right: 3rem; /* Adds padding on the right for spacing between items */
    padding-top: 1rem;
    display: flex;       /* Enables flexbox, which is a row layout by default */
    justify-content: center; /* Centers children horizontally in the flex container */
    align-items: center; /* Centers children vertically in the flex container */
    flex-direction: row; /* Explicitly set to row to avoid confusion and ensure horizontal layout */
}


.nav div.main_list ul li a {
    text-decoration: none;
    color: #fff;
    line-height: 65px;
    font-size: 2.4rem;
}

.nav div.main_list ul li a:hover {
    color: #37F0FF;
}

.menu {
  color: white;
  display: flex;
  align-items: center;
  float: right;
  padding-right: 10px;
  padding-top: 10px;
  position: relative;
}

.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown-toggle {
  padding: 10px;
  border: 1px solid #7701BC;
  border-radius: 5px;
  cursor: pointer;
  background-color: #7701BC; /* Set a background color for better visibility */
  color: white;
  transition: background-color 0.2s; /* Smooth transition for background color */
}

.dropdown-toggle:hover {
  background-color: #b73bff; /* Slightly lighter on hover */
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Adding a box shadow for depth */
  z-index: 10;
  display: none; /* Initially hidden */
  min-width: 160px; /* Minimum width */
}

.dropdown.open .dropdown-menu {
  display: block; /* Show dropdown when open */
}

.dropdown-item {
  padding: 12px 16px; /* Increased padding for better touch area */
  cursor: pointer;
  color: #333; /* Set a text color for dropdown items */
  background-color: #fff; /* Set a background color for dropdown items */
  transition: background-color 0.2s; /* Smooth transition for background color */
}

.dropdown-item:hover {
  background-color: #007bff; /* Background color on hover */
  color: white; /* Text color on hover */
}

/* Home section */

.home {
    width: 100%;
    height: 100vh;
    background-image: url(https://images.unsplash.com/photo-1498550744921-75f79806b8a7?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=b0f6908fa5e81286213c7211276e6b3d&auto=format&fit=crop&w=1500&q=80);
    background-position: center top;
	background-size:cover;
}

.navTrigger {
    display: none; /* Hides the trigger on larger screens */
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1000; /* Ensures it's above other content */
}

.nav {
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
.addresstext{
    color: #fff;
    font-size: 2rem;
}

.navItems {
    color: #fff;
    font-size: 2rem;
    transition: color 0.3s ease, transform 0.3s ease;  /* Smooth transition for color and transform */
}

.navItems:hover {
    color: #ccc;  /* Lighter shade when hovered */
    transform: scale(1.1);  /* Slightly increase the size */
    cursor: pointer;  /* Changes cursor to pointer arrow when hovered */
}

/* Media qurey section */

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .container {
        margin: 0;
    }
}

@media screen and (max-width:768px) {
    .navTrigger {
        display: block; /* Ensures the hamburger menu is visible only on small screens */
    }

    .nav div.logo {
        margin-left: 15px; /* Adds a little padding on the left for the logo */
    }

    .nav div.main_list {
        display: none; /* Initially hidden on mobile */
        width: 100%;
        position: absolute;
        background-color: #4245f5; /* Dark blue background for visibility */
        top: 65px; /* Positioned right below the navbar */
        left: 0;
        z-index: 900;    
    }

    .nav div.main_list.show_list { /* Combining .show_list to work with .main_list directly */
        display: block; /* Shows the list when .show_list is added via JavaScript */
    }

    .nav div.main_list ul {
        flex-direction: column;
        width: 100%;
        height: 100vh;
        background-color: rgba(25, 50, 90, 1); /* Updated for consistency and clarity */
        padding: 0; /* Removes any default padding */
        margin: 0; /* Removes any default margin */
    }

    .nav div.main_list ul li {
        width: 100%;
        text-align: center; /* Center-align the items uniformly */
        justify-content: center; /* Centers content horizontally */
        align-items: center; /* Centers content vertically */
    }

    .nav div.main_list ul li a {
        display: block; /* Ensures that the link uses the full width for better tap targets */
        width: 100%;
        font-size: 3rem;
        padding: 20px;
        color: white; /* Ensures text color is white */
        text-decoration: none; /* Removes underline from links */
        display: flex;
        justify-content: center;
    }

    .nav .login-button {
        background-color: transparent; /* Light blue background */
        color: white; /* White text */
        border: none; /* No border */
        cursor: pointer;
        font-size: 16px; /* Slightly larger text */
        font-weight: bold;
        padding: 10px 140px; /* Updated padding for better touch interaction */
        width: auto; /* Allows the button to size according to its content */
        height: auto;
        text-align: center;
        line-height: 30px; /* Center the text vertically */
        border-radius: 5px; /* Rounded corners */
        transition: background-color 0.3s; /* Smooth transition for hover effect */
    }

    .login-button:hover {
        background-color: #87CEEB; /* A lighter blue when hovered */
    }

    .nav .whitepaper-button {
        background-color: transparent ; /* Light blue background */
        color: white; /* White text */
        border: none; /* No border */
        cursor: pointer;
        font-size: 16px; /* Slightly larger text */
        font-weight: bold;
        padding: 10px 140px; /* Updated padding for better touch interaction */
        width: auto; /* Allows the button to size according to its content */
        height: auto;
        text-align: center;
        line-height: 30px; /* Center the text vertically */
        border-radius: 5px; /* Rounded corners */
        transition: background-color 0.3s; /* Smooth transition for hover effect */
    }

    .whitepaper-button:hover {
        background-color: #F8F8F8; /* A lighter blue when hovered */
    }
}


/* Animation */
/* Inspiration taken from Dicson https://codemyui.com/simple-hamburger-menu-x-mark-animation/ */

.navTrigger {
    cursor: pointer;
    width: 30px;
    height: 25px;
    margin: auto;
    position: absolute;
    right: 30px;
    top: 0;
    bottom: 0;
}

.navTrigger i {
    background-color: #fff;
    border-radius: 2px;
    content: '';
    display: block;
    width: 100%;
    height: 4px;
}

.navTrigger i:nth-child(1) {
    -webkit-animation: outT 0.8s backwards;
    animation: outT 0.8s backwards;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}

.navTrigger i:nth-child(2) {
    margin: 5px 0;
    -webkit-animation: outM 0.8s backwards;
    animation: outM 0.8s backwards;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}

.navTrigger i:nth-child(3) {
    -webkit-animation: outBtm 0.8s backwards;
    animation: outBtm 0.8s backwards;
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}

.navTrigger.active i:nth-child(1) {
    -webkit-animation: inT 0.8s forwards;
    animation: inT 0.8s forwards;
}

.navTrigger.active i:nth-child(2) {
    -webkit-animation: inM 0.8s forwards;
    animation: inM 0.8s forwards;
}

.navTrigger.active i:nth-child(3) {
    -webkit-animation: inBtm 0.8s forwards;
    animation: inBtm 0.8s forwards;
}

@-webkit-keyframes inM {
    50% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(45deg);
    }
}

@keyframes inM {
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(45deg);
    }
}

@-webkit-keyframes outM {
    50% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(45deg);
    }
}

@keyframes outM {
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(45deg);
    }
}

@-webkit-keyframes inT {
    0% {
        -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
        -webkit-transform: translateY(9px) rotate(0deg);
    }
    100% {
        -webkit-transform: translateY(9px) rotate(135deg);
    }
}

@keyframes inT {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(9px) rotate(0deg);
    }
    100% {
        transform: translateY(9px) rotate(135deg);
    }
}

@-webkit-keyframes outT {
    0% {
        -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
        -webkit-transform: translateY(9px) rotate(0deg);
    }
    100% {
        -webkit-transform: translateY(9px) rotate(135deg);
    }
}

@keyframes outT {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(9px) rotate(0deg);
    }
    100% {
        transform: translateY(9px) rotate(135deg);
    }
}

@-webkit-keyframes inBtm {
    0% {
        -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
        -webkit-transform: translateY(-9px) rotate(0deg);
    }
    100% {
        -webkit-transform: translateY(-9px) rotate(135deg);
    }
}

@keyframes inBtm {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(-9px) rotate(0deg);
    }
    100% {
        transform: translateY(-9px) rotate(135deg);
    }
}

@-webkit-keyframes outBtm {
    0% {
        -webkit-transform: translateY(0px) rotate(0deg);
    }
    50% {
        -webkit-transform: translateY(-9px) rotate(0deg);
    }
    100% {
        -webkit-transform: translateY(-9px) rotate(135deg);
    }
}

@keyframes outBtm {
    0% {
        transform: translateY(0px) rotate(0deg);
    }
    50% {
        transform: translateY(-9px) rotate(0deg);
    }
    100% {
        transform: translateY(-9px) rotate(135deg);
    }
}

.affix {
    padding: 0;
    background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(39, 55, 75) 45%, #3e3043 75%);
    height: 8%
}
