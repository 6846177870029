@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira:wght@300&display=swap');

.Title{
    position: relative;

}

#bannerImage {
  width: 80%;       /* Scales width to 80% of its container */
  height: auto;     /* Scales height automatically to maintain the aspect ratio */
  max-width: 800px; /* Maximum width of the image */
  max-height: 600px; /* Maximum height of the image */
  object-fit: contain; /* Ensures the image is scaled to maintain its aspect ratio */
}

.groupImageandLiner {
  display: flex;           /* Establishes a flex container and aligns children in a row by default */
  align-items: center;     /* Aligns children vertically in the center */
  justify-content: center; /* Horizontally centers the content within the container */
  width: 100%;             /* Takes the full width of its parent container */
}


.caption-container {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
    animation: textGlitch 3s ease-in-out infinite alternate;
    z-index: 50;
    flex-direction: column; /* Add this line */ 
}

.animated-title {
    
    color: white;
    font-size: 100px;
    margin: 10px;
    width: 100%;
    text-align: center;
    font-family: 'Saira';
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20vh;
    animation: textGlitch 3s ease-in-out infinite alternate;
    z-index: 50;
    position: relative;
    flex-direction: column; /* Add this line */ 
}

.animated-element {
    display: inline-block;
    min-width: 0.2rem;
    opacity: 0;
    transform: translateY(2px);
    animation: displayLetter 1s ease-in-out forwards alternate;
    letter-spacing: 1px;
    animation-delay: calc(0.05s * var(--i));
}

.sub-caption{
  color: white;
  font-size: 100px;
  margin: 10px;
  text-align: center;
  font-family: 'Saira';
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20vh;
  animation: textGlitch 3s ease-in-out infinite alternate;
  z-index: 50;
  position: relative;
  flex-direction: column; /* Add this line */ 
}


.button-hyperlink {
  padding: 6px 12px;
  background-color: white;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  display: flex; /* Set display to flex to help center content */
  align-items: center; /* Align items vertically in the center */
  justify-content: center; /* Center content horizontally */
  text-decoration: none;
}

.button-hyperlink span {
  font-size: 20px;
  font-family: 'Saira';
  color: black;
  position: relative;
  z-index: 1;
  transition: color 0.6s cubic-bezier(0.53, 0.21, 0, 1);
}

.button-hyperlink::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  border-radius: 6px;
  transform: translate(-100%, -50%);
  width: 100%;
  height: 100%;
  background-color: hsl(244, 63%, 69%);
  transition: transform 0.6s cubic-bezier(0.53, 0.21, 0, 1);
}

.button-hyperlink::hover span {
  color: white;
}

.button-hyperlink:hover::before {
  transform: translate(0, -50%);
}

@media screen and (max-width: 768px) {
  .sub-caption {
    font-size: 40px; /* Reduced font size for smaller screens */
    height: auto; /* Adjust height to be auto to accommodate smaller font size */
    padding: 5px 10px; /* Adjust padding to reduce space usage */
    margin: 5px; /* Smaller margin to use space more efficiently */
  }
}


@keyframes displayLetter {
	0% {
    transform: translateY(2px);
    color: black;
    opacity: 0;
	}
  10% {
    opacity: 1;
    color: #0C55D8;
  }
  20% {
    opacity: 0;
    color: #084286;
    transform: translateY(0px);
  }
  50% {
    opacity: 1;
    color: purple;
    transform: translateY(1px);
  }
  60% {
    opacity: 1;
    color: #7701BC;
    transform: translateY(1px);
  }
	100% {
    transform: translateY(0);
    color: white;
    opacity: 1;
	}
}
