@import url('https://fonts.googleapis.com/css2?family=Saira:wght@300&display=swap');

.card-container {
    display: flex;
    flex-direction: row; /* Align cards horizontally */
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Disable vertical scrolling within the container */
    white-space: nowrap; /* Ensure cards are in one line */
    padding: 20px; /* Padding around the cards */
    gap: 10px; /* Space between the cards */
    max-height: 250px; /* Maximum height of the container */
}

  
  /* Styling for the scrollbar for better visual appearance */
  .card-container::-webkit-scrollbar {
    height: 8px; /* Height of the horizontal scrollbar */
  }
  
  .card-container::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Rounded corners on the scrollbar thumb */
  }
  
  .card-container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb on hover */
  }
  