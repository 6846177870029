.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
    max-height: 400px; /* Maximum height set to limit the overall height */
    min-height: 200px; /* Minimum height to ensure visibility of content */
    background-color: white;
    border-radius: 15px; /* Soft rounded corners */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    overflow: auto; /* Enables scrolling if content exceeds the popup height */
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: transparent;
}

.closeButton {
    border: 2px solid black;
    border-radius: 5px;
    background: none;
    color: black;
    cursor: pointer;
    font-size: 20px;
    padding: 5px 10px;
    transition: transform 0.3s ease;
}

.closeButton:hover {
    transform: scale(1.1);
}

.content {
    flex-grow: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image {
    width: 80%;
    height: auto;
    margin-bottom: 20px;
}

.signupButton {
    width: 100%;
    padding: 10px 20px;
    background-color: lightblue;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
}

/* Arrow Styles */
.arrow-container {
    grid-row: 3;
    grid-column: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .arrow {
    border: solid rgb(120, 126, 184);
    border-width: 0 8px 8px 0;
    padding: 10px;
    transform: rotate(-45deg);
    animation: pop 1.5s infinite;
  }
/* Styling for images within the content */
.content img {
    width: 100%; /* Responsive width */
    max-width: 300px; /* Limiting image size */
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center the image */
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
    max-height: 400px; /* Maximum height set to limit the overall height */
    min-height: 200px; /* Minimum height to ensure visibility of content */
    background-color: white;
    border-radius: 15px; /* Soft rounded corners */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    overflow: auto; /* Enables scrolling if content exceeds the popup height */
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: transparent;
}

.closeButton {
    border: 2px solid black;
    border-radius: 5px;
    background: none;
    color: black;
    cursor: pointer;
    font-size: 20px;
    padding: 5px 10px;
    transition: transform 0.3s ease;
}

.closeButton:hover {
    transform: scale(1.1);
}

.image {
    width: 80%;
    height: auto;
    margin-bottom: 20px;
}

.signupButton {
    width: 100%;
    padding: 10px 20px;
    background-color: lightblue;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
}

/* Arrow Styles */
.arrow-container {
    grid-row: 3;
    grid-column: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .arrow {
    border: solid rgb(120, 126, 184);
    border-width: 0 8px 8px 0;
    padding: 10px;
    transform: rotate(-45deg);
    animation: pop 1.5s infinite;
  }
/* Styling for images within the content */
.content img {
    width: 100%; /* Responsive width */
    max-width: 300px; /* Limiting image size */
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center the image */
    padding-top: 100px;
  }

  .content {
    flex-grow: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

  .video-content {
    text-align: left;
    padding: 10px 20px; /* Control spacing inside the container */
    width: 100%; /* Ensures the div uses the full width provided by its parent */
    box-sizing: border-box; /* Includes padding and border in the width and height */
    max-height: 150px; /* Example fixed height; adjust based on your design needs */
    overflow: hidden; /* Hides any content that overflows this height */
    display: flex; /* Implements flexbox layout */
    flex-direction: column; /* Stacks children vertically */
}


