.video-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 800px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    z-index: 1000; /* Make sure this is above other content */
    display: flex;
    flex-direction: column;
}

.fullscreen-btn {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.video-container {
    width: 100%;
    flex: 1 1 auto; /* Take up half the space of the popup */
}

video {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
}

.info-container {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between; /* Space between description and learner count */
}

.description {
    flex: 3; /* Takes more space */
    padding-right: 20px; /* Spacing between text and learner count */
}

.learner-count {
    flex: 1; /* Takes less space */
    text-align: right;
}

.close-btn {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    background-color: #f00;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
