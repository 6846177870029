/* ErrorPopup.css */

.error-popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.9); /* Adjust the alpha value (0.8) to control opacity */
    color: #fff;
    padding: 200px; /* Adjust padding to increase size */
    border-radius: 10px; /* Adjust border radius for rounded corners */
    text-align: center;
    opacity: 0;
    animation: show-new .7s ease-in-out;
    z-index: 1000;
    width: 100%;
  }
  
  .error-popup.visible {
    display: block;
    opacity: 1;
  }
  
  .popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Saira';
    font-size: 1.1rem;
  }
  
  #okbtn {
    position: relative;
  
    height: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    background: #E6E6FA;
    color: white;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: background .3s ease-in-out;
    width: 15%;
  }
  
  .hide {
    animation: hide-new .7s ease-in-out;
  }
  
  #okbtn:hover {
    color: white;
    background: #7B68EE;
  }
  
  @keyframes show-new {
    0% {
      transform: translate(-50%, -50%) scale(0); /* Ensure it starts centered and scaled down */
    }
    60% {
      transform: translate(-50%, -50%) scale(1.1);
    }
    80% {
      transform: translate(-50%, -50%) scale(0.9);
    }
    100% {
      transform: translate(-50%, -50%) scale(1.0);
    }
  }
  
  @keyframes hide-new {
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(0);
    }
  }
  
  #keith{
    color: #69faff;
  }
  
  #eugene{
    color: #5490ff;
  }
  #linkurl{
    color: #aea0fe;
  }

  .illustration{
    width: 30%;
    height: 3rem;
  }

 /* Responsive adjustments for mobile devices */
@media screen and (max-width: 768px) {
  .error-popup {
      padding: 20px; /* Further reduce padding */
      width: 95%; /* Increase width to use more screen space */
  }

  #okbtn {
      width: 100%; /* Make button full-width for easier access */
  }

  .popup-content {
      font-size: 1.2rem; /* Increase font size for better readability */
  }

  h2, p {
      font-size: 1vh; /* Increase font size slightly */
  }
}
  
  mark{
    background: transparent;
    width: 50%;
    color: white;
    display: flex;
    justify-content: center; /* Centers children horizontally */
    align-items: center;     /* Centers children vertically */
    flex-direction: column;
  }
  
  img{
    height: 10rem;
  }

/* Media Query for screens with an aspect ratio greater than 16/9 and a minimum width */
@media screen and (min-aspect-ratio: 16/9) and (min-width: 1920px) {
  .error-popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.9); /* Adjust the alpha value (0.8) to control opacity */
    color: #fff;
    padding: 200px; /* Adjust padding to increase size */
    border-radius: 10px; /* Adjust border radius for rounded corners */
    text-align: center;
    opacity: 0;
    animation: show-new .7s ease-in-out;
    z-index: 1000;
    width: 100%;
  }

  .popup-content {
      font-size: calc(1rem + 0.2vw); /* Increase font size slightly based on width */
  }

  #okbtn {
      width: 20%; /* Increase width proportionally */
      font-size: 1.2rem; /* Increase font size for better readability */
  }

  h2, p {
      font-size: 2.5rem; /* Larger text for easier reading on large screens */
  }

  .illustration {
      width: 40%; /* Adjust illustration width */
      height: auto; /* Maintain aspect ratio */
  }
}
