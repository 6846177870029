/* ClickableCard.css */
.clickable-card {
    width: 400px; /* fixed width */
    height: 250px; /* fixed height */
    flex-shrink: 0; /* prevents shrinking */
    flex-grow: 0; /* prevents growing */
    flex-basis: auto; /* default value, could set to 400px to reinforce width */
}


/* Enlarging effect on hover */
.clickable-card:hover {
    transform: scale(1.05); /* Slightly enlarges the card */
    box-shadow: 0 4px 8px rgba(0,0,0,0.3); /* Enhances the shadow for a lifted effect */
}

/* Image setup */
.clickable-card img {
    width: 100%; /* Image covers the full width */
    height: 140px; /* Fixed height for the image */
    object-fit: cover; /* Ensures the image covers the area, might crop */
    transition: opacity 0.3s ease; /* Smooth fade for any future opacity changes */
}

/* Text styling */
.clickable-card h3,
.clickable-card p {
    color: white; /* White text for visibility */
    padding: 5px 20px; /* Padding for spacing text nicely */
    transition: color 0.3s ease; /* Smooth color transition */
    overflow: hidden; /* Hide overflow content */
    white-space: nowrap; /* Keep text in a single line */
    text-overflow: ellipsis; /* Use ellipsis for overflow text */
    width: calc(100% - 40px); /* width with padding accounted */
}


/* Enlarging effect on hover */
.clickable-card:hover {
    transform: scale(1.05); /* Slightly enlarges the card */
    box-shadow: 0 4px 8px rgba(0,0,0,0.3); /* Enhances the shadow for a lifted effect */
}

/* Image setup */
.clickable-card img {
    width: 100%; /* Image covers the full width */
    height: 140px; /* Fixed height for the image */
    object-fit: cover; /* Ensures the image covers the area, might crop */
    transition: opacity 0.3s ease; /* Smooth fade for any future opacity changes */
}

/* Text styling */
.clickable-card h3,
.clickable-card p {
    color: white; /* White text for visibility */
    padding: 5px 20px; /* Padding for spacing text nicely */
    transition: color 0.3s ease; /* Smooth color transition */
}
