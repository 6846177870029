.card {
    width: 150px;
    margin: 10px;
    padding: 20px;
    background-color: #f0f0f0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    border-radius: 5px;
    text-align: center;
    overflow: hidden; /* Ensures that the content does not overflow the card boundaries */
  }
  
  .card img {
    max-width: 50%; /* Makes the image responsive, ensuring it scales down if it's too wide for the card */
    height: auto; /* Maintains the aspect ratio of the image */
    margin-bottom: 10px; /* Adds some space between the image and the text below it */
    border-radius: 5px; /* Optional: Rounds the corners of the image to match the card */
  }

a {
  text-decoration: none;
  color: black;
}

/* Media Query for screens with an aspect ratio greater than 16/9 and a minimum width */
@media screen and (min-aspect-ratio: 16/9) and (min-width: 1920px) {
  body .card {
    width: 150px;
    margin: 10px;
    padding: 40px;
    background-color: #f0f0f0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    border-radius: 5px;
    text-align: center;
    overflow: hidden; /* Ensures that the content does not overflow the card boundaries */
  }
  
  body .card img {
    max-width: 50%; /* Makes the image responsive, ensuring it scales down if it's too wide for the card */
    height: auto; /* Maintains the aspect ratio of the image */
    margin-bottom: 10px; /* Adds some space between the image and the text below it */
    border-radius: 5px; /* Optional: Rounds the corners of the image to match the card */
  }

a {
  text-decoration: none;
  color: black;

}

body .h2, p {
  font-size: 1.5rem;
}
}