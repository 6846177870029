.popup-form {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
}

.form-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 300px;  /* Adjusted for better visibility */
    position: relative;
    display: flex;       /* Added for flexbox layout */
    flex-direction: column;  /* Stack children vertically */
    align-items: center;  /* Center-align the flex children horizontally */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: large;
    cursor: pointer;
}

h2, p {              /* Center-align text elements */
    width: 100%;
    text-align: center;
}

select {
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    display: block;  /* Keep as block to fill width */
    width: 100%;     /* Specify width to match parent's width */
}

.submit-button, .close-button {
    background-color: #1616c4;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 2px;
    cursor: pointer;
    border-radius: 5px;
    width: auto;  /* Ensure buttons width are content-based */
}

.submit-button {
    background-color: #1616c4; /* Specific to submit button for distinct color */
}

.image-content {
    max-width: 25%;
    height: auto;
    margin-bottom: 10px;
    border-radius: 5px;
}
